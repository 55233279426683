import { useContext } from 'react';
import { useFormik } from 'formik';
import { useGetBlockWithBanners, useBlockPut } from '../services/blocksService';
import {
  NotificationContext,
  NotificationStatus,
} from '../components/notification-bar/notification-bar.context';
import { useLanguage } from './useLanguage';

export function useEditBlock(type, isArray) {
  const { language } = useLanguage();
  const { updateNotification } = useContext(NotificationContext);
  const { data, isLoading, onUpdate } = useGetBlockWithBanners(type, language);
  const { mutate: onUpdateBlock } = useBlockPut();
  const initialValues = isArray ? data.block : data.block[0];

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (values.length > 0) {
        values.map((item) => onUpdateBlock(item));
      } else {
        onUpdateBlock(values);
      }
      updateNotification({ message: 'Successful!', status: NotificationStatus.success });
    },
    enableReinitialize: true,
  });

  return { data, formik, isLoading, onUpdate };
}
