import React, { createContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLocalStorage } from './useLocalStorage';
import { useAuthService } from '../services/authService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('token_user', null);
  const { mutate } = useAuthService();
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = (params) => {
    // eslint-disable-next-line no-console
    console.log(params);
    mutate(params);
    // setUser(data);
    // navigate('/profile');
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate('/', { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};
