import { useContext } from 'react';
import { useMutation } from 'react-query';
import { apiService } from './api-service';
import {
  NotificationContext,
  NotificationStatus,
} from '../components/notification-bar/notification-bar.context';
import { useLanguage } from '../utils/useLanguage';

export function useBannerList() {
  const { language } = useLanguage();
  const { mutate, isSuccess, data } = useMutation('useBannerList', (params) =>
    apiService.post(`bannerobject/get/`, { language, ...params })
  );
  return { mutate, isSuccess, data };
}

export function useBannerPut() {
  const { language } = useLanguage();
  const { updateNotification } = useContext(NotificationContext);
  const { mutate, isSuccess, data } = useMutation(
    'useBannerPut',
    (item) => apiService.put(`bannerobject/put/`, { language, ...item }),
    {
      onSuccess: () => {
        updateNotification({ message: 'Successful!', status: NotificationStatus.success });
      },
    }
  );
  return { mutate, isSuccess, data };
}

export function useBannerDelete() {
  const { updateNotification } = useContext(NotificationContext);
  const { mutate, isSuccess, data } = useMutation(
    'useBannerPut',
    (item) => apiService.delete(`bannerobject/`, item),
    {
      onSuccess: () => {
        updateNotification({ message: 'Successful!', status: NotificationStatus.success });
      },
    }
  );
  return { mutate, isSuccess, data };
}
