import React from 'react';
import { Paper } from '@mui/material';
import { Form, FormikProvider } from 'formik';
import CardsList from '../../components/cards-list/cards-list.component';
import PaperFooter from '../../components/paper-footer/paper-footer.component';
import { useEditBlock } from '../../utils/useEditBlock';
import { blockTypes } from '../../constants/blockTypes';

function MainBanner() {
  const { data, formik, onUpdate } = useEditBlock(blockTypes.main_banner);

  return (
    <FormikProvider value={formik}>
      <Form key="sad" autoComplete="off" noValidate>
        <Paper sx={{ p: 2, gap: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <CardsList items={data.banners} onUpdate={onUpdate} />
          <PaperFooter />
        </Paper>
      </Form>
    </FormikProvider>
  );
}

export default MainBanner;
