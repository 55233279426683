/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Paper,
  Stack,
  Typography,
  Button,
  Rating,
  ToggleButtonGroup,
} from '@mui/material';
import { useCommentDelete, useCommentPut, useCommentsGet } from '../../services/commentsService';
import {
  NotificationContext,
  NotificationStatus,
} from '../../components/notification-bar/notification-bar.context';
import { ToggleButton } from '@mui/lab';

function Comments() {
  const [moderation, setModeration] = useState('false');
  const { data, mutate } = useCommentsGet();
  const { updateNotification } = useContext(NotificationContext);
  const { mutate: deleteComment, isSuccess: isDeleted } = useCommentDelete();
  const { mutate: updateComment, isSuccess: isUpdated } = useCommentPut();

  const loadComments = () => {
    mutate({ ID_nomenclature: '', moderation });
  };

  useEffect(() => {
    if (isUpdated) {
      updateNotification({ message: 'Комментарий изменен!', status: NotificationStatus.success });
      loadComments();
    }
  }, [isUpdated]);

  useEffect(() => {
    if (isDeleted) {
      updateNotification({ message: 'Комментарий удален!', status: NotificationStatus.success });
      loadComments();
    }
  }, [isDeleted]);

  useEffect(() => {
    loadComments();
  }, [moderation]);

  useEffect(() => {
    loadComments();
  }, []);

  return (
    <Paper sx={{ p: 2, gap: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <ToggleButtonGroup
        color="primary"
        value={moderation}
        exclusive
        onChange={(event, value) => {
          setModeration(value);
        }}
        aria-label="Platform"
      >
        <ToggleButton value="false">Не опубликованные</ToggleButton>
        <ToggleButton value="true">Опубликованные</ToggleButton>
      </ToggleButtonGroup>
      <Stack spacing={4}>
        {data &&
          data.map((item) => (
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  <Rating name="read-only" value={item.grade} readOnly />
                </Typography>
                <Typography>{item.comment}</Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    updateComment({
                      ID_nomenclature: item.ID_nomenclature,
                      comment: item.comment,
                      grade: item.grade,
                      parentID: item.parentID,
                      user: item.user,
                      ID_message: item.ID,
                      moderation: (!(item.moderation === 'true')).toString(),
                    });
                  }}
                >
                  {item.moderation === 'true' ? 'Спрятать' : 'Опубликовать'}
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    deleteComment({ id: item.ID });
                  }}
                >
                  Удалить
                </Button>
              </CardActions>
            </Card>
          ))}
      </Stack>
    </Paper>
  );
}

export default Comments;
