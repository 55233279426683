import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { apiService } from './api-service';
import { useBannerList } from './bannersService';

export function useGetBlock() {
  const { mutate, isSuccess, data } = useMutation('useGetBlock', (params) =>
    apiService.post(`adminpanel/get/`, params)
  );
  return { mutate, isSuccess, data };
}

export function useGetBlockWithBanners(type, language) {
  const {
    data: block,
    mutate: getBlock,
    isLoading: isBlockLoading,
    isSuccess: isBlockSuccess,
  } = useGetBlock();
  const {
    data: banners,
    mutate: getBanners,
    isLoading: isBannersLoading,
    isSuccess: isBannersSuccess,
  } = useBannerList();

  const onUpdate = () => {
    getBanners({ type, language });
    getBlock({ type, language });
  };

  useEffect(() => {
    if (language) {
      onUpdate();
    }
  }, [language]);

  return {
    onUpdate,
    data: {
      banners:
        banners && banners.length > 0 && Array.isArray(banners)
          ? banners.filter((item) => item.type === type && item.language === language)
          : 0,
      block: block && block.length > 0 ? block : [],
    },
    isLoading: isBlockLoading || isBannersLoading,
    isSuccess: isBlockSuccess && isBannersSuccess,
  };
}

export function useBlockPut() {
  const { mutate, isSuccess, data } = useMutation('useBlockPut', (params) =>
    apiService.put(`adminpanel/put/`, { ...params })
  );
  return { mutate, isSuccess, data };
}

export function useGetSeo() {
  const { mutate, isSuccess, data } = useMutation('useGetSeo', (params) =>
    apiService.post(`CEOForPages`, params)
  );
  return { mutate, isSuccess, data };
}

export function usePutSeo() {
  const { mutate, isSuccess, data } = useMutation('usePutSeo', (params) =>
    apiService.put(`CEOForPages`, { id: uuidv4(), ...params })
  );
  return { mutate, isSuccess, data };
}
