import React, { useEffect } from 'react';
import {
  Typography,
  Fab,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { Link, generatePath } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useBannerDelete } from '../../services/bannersService';
import { useLanguage } from '../../utils/useLanguage';

function CardsList({ items, addMoreText, addMoreLink, onUpdate }) {
  const { language } = useLanguage();
  const { mutate, isSuccess } = useBannerDelete();

  useEffect(() => {
    if (onUpdate && isSuccess) onUpdate();
  }, [isSuccess]);

  return (
    <>
      <Grid container spacing={2}>
        {items && Array.isArray(items) && items.length > 0 ? (
          items
            .filter((item) => item.language === language)
            .map((item) => (
              <Grid item xs={3}>
                <Card sx={{ width: 230 }}>
                  <CardMedia
                    id={uuidv4()}
                    component="img"
                    alt="image"
                    height="140"
                    image={`${item.image}?uuid=${uuidv4()}` || 'https://via.placeholder.com/230'}
                  />
                  {(item.title || item.description || item.type) && (
                    <CardContent>
                      {item.type && (
                        <Typography gutterBottom variant="body2" component="text.secondary">
                          {item.type}
                        </Typography>
                      )}
                      {item.title && (
                        <Typography gutterBottom variant="h5" component="div">
                          {item.title}
                        </Typography>
                      )}
                      {item.description && (
                        <Typography variant="body2" color="text.secondary">
                          {item.description}
                        </Typography>
                      )}
                    </CardContent>
                  )}
                  <CardActions sx={{ gap: 2 }}>
                    <Button
                      component={Link}
                      to={{
                        pathname: generatePath(`/dashboard/banners/:id`, { id: item.id }),
                        search: `?lang=${language}`,
                      }}
                      size="small"
                      variant="contained"
                    >
                      Изменить
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => mutate({ id: item.id, language: item.language })}
                    >
                      Удалить
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
        ) : (
          <Grid item xs={3}>
            Нет доступных баннеров
          </Grid>
        )}
      </Grid>
      {addMoreText && (
        <Fab
          component={Link}
          to={addMoreLink || ''}
          aria-label="add"
          color="primary"
          variant="extended"
          margin="normal"
        >
          <AddIcon sx={{ mr: 1 }} />
          {addMoreText}
        </Fab>
      )}
    </>
  );
}

CardsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  addMoreText: PropTypes.string,
  addMoreLink: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default CardsList;
