import React, { useState } from 'react';
import { Paper, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import PaperFooter from '../../components/paper-footer/paper-footer.component';
import ImageUpload from '../../components/image-upload/image-upload.components';
import Dropdown from '../../components/dropdown/dropdown.component';
import { bannerTypes } from '../../constants/blockTypes';
import { changeBannerType } from '../../utils/banners';

function EditBanner({ item, saveBanner, onCancel }) {
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: item,
    onSubmit: (values) => {
      saveBanner(values);
    },
  });

  const { getFieldProps, values, setFieldValue, setValues } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        <Paper sx={{ p: 2, gap: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <ImageUpload
            baseId={item.id}
            image={values.image || ''}
            setImage={(link) => setFieldValue('image', link)}
            setIsLoading={setIsLoading}
          />
          {values.title !== undefined && (
            <TextField
              fullWidth
              required
              name="title"
              label="Title"
              type="text"
              id="Title"
              {...getFieldProps('title')}
            />
          )}
          {values.description !== undefined && (
            <TextField
              fullWidth
              required
              name="title"
              label="Description"
              type="text"
              id="description"
              {...getFieldProps('description')}
            />
          )}
          {values.link !== undefined && (
            <TextField
              required
              fullWidth
              name="Link"
              label="Link"
              type="text"
              id="link"
              {...getFieldProps('link')}
            />
          )}
          {values.button_link1 !== undefined && (
            <TextField
              required
              fullWidth
              name="Button text"
              label="Button text"
              type="text"
              id="Button text"
              {...getFieldProps('button_text1')}
            />
          )}
          {values.button_text1 !== undefined && (
            <TextField
              required
              fullWidth
              name="Button link"
              label="Button link"
              type="text"
              id="Button text"
              {...getFieldProps('button_link1')}
            />
          )}
          {values.button_link2 !== undefined && (
            <TextField
              required
              fullWidth
              name="Button text"
              label="Button text"
              type="text"
              id="Button text"
              {...getFieldProps('button_text2')}
            />
          )}
          {values.button_text2 !== undefined && (
            <TextField
              required
              fullWidth
              name="Button link"
              label="Button link"
              type="text"
              id="Button text"
              {...getFieldProps('button_link2')}
            />
          )}
          <Dropdown
            label="Тип баннера"
            items={Object.values(bannerTypes)}
            value={values.type}
            onChange={(e) => {
              setValues(changeBannerType({ ...item, type: e }));
              setFieldValue('type', e);
            }}
          />
          <PaperFooter isLoading={isLoading} onCancel={onCancel} />
        </Paper>
      </Form>
    </FormikProvider>
  );
}

EditBanner.propTypes = {
  item: PropTypes.object,
  saveBanner: PropTypes.func,
  onCancel: PropTypes.func,
};

export default EditBanner;
