import { useMutation } from 'react-query';
import { apiService } from './api-service';
import { useLanguage } from '../utils/useLanguage';

export function useNomenclatureGet() {
  const { language } = useLanguage();
  const { mutate, isSuccess, data } = useMutation('useNomenclatureGet', (params) =>
    apiService.post(`nomenclature/getnomenclature/`, {
      language,
      page_number: '0',
      items: '10',
      ...params,
      token_user: '',
    })
  );
  return { mutate, isSuccess, data };
}
