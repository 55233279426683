import React from 'react';
import { Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';

function PaperFooter({ onSubmit, onCancel, isLoading }) {
  return (
    <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'flex-end' }}>
      <LoadingButton
        loading={isLoading}
        type="submit"
        size="large"
        variant="contained"
        color="primary"
        onClick={onSubmit}
      >
        Сохранить
      </LoadingButton>
      <Button type="reset" size="large" variant="outlined" color="primary" onClick={onCancel}>
        Отменить
      </Button>
    </Stack>
  );
}

PaperFooter.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default PaperFooter;
