import React from 'react';
import { Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Form, FormikProvider } from 'formik';
import PaperFooter from '../../components/paper-footer/paper-footer.component';
import { blockTypes } from '../../constants/blockTypes';
import { useEditBlock } from '../../utils/useEditBlock';

function AboutUs() {
  const { data, formik } = useEditBlock(blockTypes.about_us);
  const { getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form key={JSON.stringify(data)} autoComplete="off" noValidate>
        <Paper sx={{ p: 2, gap: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <TextField fullWidth required label="Title" type="text" {...getFieldProps('title')} />
          <TextField
            required
            fullWidth
            minRows={3}
            multiline
            label="Description"
            type="text"
            {...getFieldProps('description')}
          />
          <PaperFooter />
        </Paper>
      </Form>
    </FormikProvider>
  );
}

export default AboutUs;
