import axios from 'axios';

export const API_PREFIX = '/kup_r2/hs/exchange';
export const TOKEN_NAME = 'jwt-token';
export const TOKEN_SITE = 'fdsfdsfdsgfd%^7dsajhbe76rfvhgR%^RFD#27vbduyveg65ER%FGD56rf436rfgdskf';
export const USER = 'user';

export class ApiService {
  authorizationHeader = () => {
    const token = 'YWRtaW46OTEx';
    if (token) {
      return {
        Authorization: JSON.parse(token),
      };
    }
    return {};
  };

  async baseRequest(url, method, body, additional) {
    try {
      const response = await axios(`${API_PREFIX}/${url}`, {
        method,
        data: {
          token_site: TOKEN_SITE,
          token_user: '9da8d884-5080-41b8-985a-b8d5efed7561',
          ...body,
        },
        headers: { 'Content-Type': 'application/json', ...this.authorizationHeader },
        ...additional,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        localStorage.removeItem(TOKEN_NAME);
      }
    }
    return {};
  }

  post(url, body, additional) {
    return this.baseRequest(url, 'POST', body, additional);
  }

  put(url, body) {
    return this.baseRequest(url, 'PUT', body);
  }

  delete(url, body) {
    return this.baseRequest(url, 'DELETE', body);
  }

  get(url, body) {
    const query = body
      ? Object.entries(body).reduce((acc, item, index) => {
          if (index === 0) return `${acc}${item[0]}=${item[1]}`;
          return `${acc}&${item[0]}=${item[1]}`;
        }, '')
      : '';
    return this.baseRequest(`${url}?${query}`, 'GET');
  }
}
export const apiService = new ApiService();
