import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewCarousel from '@mui/icons-material/ViewCarousel';
import DashboardLayout from '../layouts/dashboard-layout/dashboard-layout.component';
import MainBanner from '../pages/main-banner/mainbanner.component';
import Bestsellers from '../pages/bestsellers/bestsellers.component';
import VideoBlock from '../pages/video-block/video-block.component';
import Collections from '../pages/collections/collections.component';
import RelationProducts from '../pages/relation-products/relation-products.component';
import EditBannerContainer from '../pages/edit-banner/edit-banner.container';
import Banners from '../pages/banners/banners.component';
import SignIn from '../components/signin/signin.component';
import Mock from '../pages/mock/mock.component';
import Main from '../pages/main/main.component';
import SezonHit from '../pages/sezon-hit/sezon-hit.component';
import Seo from '../pages/seo/seo.component';
import AboutUs from '../pages/about-us/about-us.component';
import Comments from '../pages/comments/comments.component';

export const searchParameters = {
  language: 'lang',
};

export const dashboardRoutes = [
  {
    path: 'main',
    title: 'Главная страница',
    breadcrumb: 'Главная страница',
    visible: true,
    icon: <DashboardIcon />,
    children: [
      {
        path: '',
        breadcrumb: 'Главная страница',
        element: <Main />,
      },
      {
        path: 'banner',
        visible: true,
        title: 'Главный баннер',
        breadcrumb: 'Главный баннер',
        element: <MainBanner />,
      },
      {
        path: 'season-hit',
        visible: true,
        title: 'Хит сезона',
        breadcrumb: 'Хит сезона',
        element: <SezonHit />,
      },
      {
        path: 'bestsellers',
        visible: true,
        title: 'Bestsellers',
        breadcrumb: 'Bestsellers',
        element: <Bestsellers />,
      },
      { path: 'video', visible: true, title: 'VideoBlock', element: <VideoBlock /> },
      {
        path: 'collections',
        visible: true,
        title: 'Collections',
        breadcrumb: 'Collections',
        element: <Collections />,
      },
      {
        path: 'relation',
        visible: true,
        title: 'Рекомендации',
        breadcrumb: 'Рекомендации',
        element: <RelationProducts />,
      },
      {
        path: 'about-us',
        visible: true,
        title: 'О нас',
        breadcrumb: 'О нас',
        element: <AboutUs />,
      },
      {
        path: 'seo',
        visible: true,
        title: 'SEO',
        breadcrumb: 'SEO',
        element: <Seo />,
      },
    ],
  },
  {
    path: 'banners',
    icon: <ViewCarousel />,
    visible: true,
    title: 'Banners',
    breadcrumb: 'Banners',
    element: <Banners />,
  },
  {
    path: 'comments',
    icon: <ViewCarousel />,
    visible: true,
    title: 'Comments',
    breadcrumb: 'Comments',
    element: <Comments />,
  },
];

export const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
  },
  {
    path: '/dashboard',
    element: <DashboardLayout languageTabs />,
    children: dashboardRoutes,
  },
  {
    path: '/dashboard/',
    element: <DashboardLayout />,
    children: [
      {
        path: `banners/:id`,
        breadcrumb: 'Banner',
        element: <EditBannerContainer />,
      },
    ],
  },
  {
    path: 'login',
    element: <SignIn />,
  },
  {
    path: 'mock',
    element: <Mock />,
  },
];
