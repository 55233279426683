/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Form, FormikProvider } from 'formik';
import PaperFooter from '../../components/paper-footer/paper-footer.component';
import { blockTypes } from '../../constants/blockTypes';
import { useEditBlock } from '../../utils/useEditBlock';
import { useNomenclatureGet } from '../../services/nomenclatureService';
import TagsInput from '../../components/tag-input/tag-input.component';

function SezonHit() {
  const [inputValue, setInputValue] = useState('');
  const { data, formik } = useEditBlock(blockTypes.seazon_hit_banner);
  const { data: nomenclature, mutate: loadNomenclature } = useNomenclatureGet();
  const { getFieldProps, values, setFieldValue } = formik;

  useEffect(() => {
    if (inputValue.length > 3) {
      loadNomenclature({ filter: { vendorCode: inputValue } });
    }
  }, [inputValue]);

  return (
    <FormikProvider value={formik}>
      <Form key={JSON.stringify(data)} autoComplete="off" noValidate>
        <Paper sx={{ p: 2, gap: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <TextField fullWidth required label="Title" type="text" {...getFieldProps('title')} />
          <TextField
            required
            label="Button text"
            type="text"
            {...getFieldProps('link_button_text')}
          />
          <TextField required label="Button link" type="text" {...getFieldProps('link_button')} />
          <TagsInput
            onChange={(items) => {
              setFieldValue('description', JSON.stringify(items));
            }}
            tags={values && values?.description?.length > 0 ? JSON.parse(values.description) : []}
            options={
              nomenclature && Array.isArray(nomenclature.data)
                ? nomenclature.data.reduce((acc, item) => {
                    return [
                      ...acc,
                      { label: `${item.vendorСode} - ${item.fullname1}`, value: item.UID },
                    ];
                  }, '')
                : []
            }
            onChangeValue={setInputValue}
            fullWidth
            variant="outlined"
            id="nomenclatures"
            name="nomenclatures"
            label="Номенклатуры"
          />
          <PaperFooter />
        </Paper>
      </Form>
    </FormikProvider>
  );
}

export default SezonHit;
