import React from 'react';

export const NotificationStatus = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

export const NotificationContext = React.createContext({
  notification: { message: '', status: NotificationStatus.success },
  updateNotification: () => {},
});
