/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { useNomenclatureGet } from '../../services/nomenclatureService';

export default function TagsInput({ ...props }) {
  const { onChange, placeholder, tags, options, onChangeValue, ...other } = props;

  return (
    <Autocomplete
      fullWidth
      value={tags}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      multiple
      id="tags-filled"
      options={options || []}
      freeSolo
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          variant="filled"
          label="Users"
          placeholder="Search"
          onChange={(event) => {
            onChangeValue(event.target.value);
          }}
          {...other}
        />
      )}
    />
  );
}

TagsInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
};
