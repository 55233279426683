/* eslint-disable */
import React, { useEffect } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { languages } from '../../constants/languages';
import { useLanguage } from '../../utils/useLanguage';

const LanguageTabs = () => {
  const { language, setLanguage } = useLanguage();

  useEffect(() => {
    if (!language) setLanguage(languages.ua);
  }, [language]);

  return language ? (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={language}
        onChange={(value, newValue) => setLanguage(newValue)}
        aria-label="basic tabs example"
      >
        {/*<Tab label="Русский" value={languages.ru} />*/}
        <Tab label="Українська" value={languages.ua} />
      </Tabs>
    </Box>
  ) : (
    <div />
  );
};

export default LanguageTabs;
