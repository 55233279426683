/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  FormLabel,
  Button,
  Stack,
  ImageList,
  ImageListItem,
  CircularProgress,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { useImagePut } from '../../services/imageService';

function getBase64(file, cb) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}

function ImageUpload({ baseId, setImage, image, setIsLoading }) {
  const [img, setImg] = useState();

  const { mutate, isSuccess, data, isLoading } = useImagePut();

  useEffect(() => {
    if (img) {
      mutate({ id: baseId, typeImageBanner: 0, image: img });
    }
  }, [img]);

  useEffect(() => {
    if (isSuccess && data) {
      setImage(data[0].link);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  return (
    <Stack direction="column" alignItems="center" spacing={2}>
      <FormLabel component="label" color="primary" sx={{ width: '100%' }}>
        Image
      </FormLabel>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Button variant="contained" component="label">
          Upload File
          <input
            type="file"
            hidden
            onChange={(event) => {
              getBase64(event.currentTarget.files[0], setImg);
            }}
          />
        </Button>
      </Stack>
      {isLoading && <CircularProgress />}
      {(data || image) && !isLoading && (
        <ImageList sx={{ display: 'block', width: 120, height: 120 }} variant="woven">
          <ImageListItem>
            <img
              src={data ? `${data[0].link}?uuid=${uuidv4()}` : image}
              alt="banner"
              loading="lazy"
            />
          </ImageListItem>
        </ImageList>
      )}
    </Stack>
  );
}

ImageUpload.propTypes = {
  baseId: PropTypes.string,
  image: PropTypes.string,
  setImage: PropTypes.func,
  setIsLoading: PropTypes.func,
};

export default ImageUpload;
