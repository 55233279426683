import { useMutation } from 'react-query';
import { apiService } from './api-service';

export function useCommentsGet() {
  const { mutate, isSuccess, data } = useMutation('useCommentsGet', (params) =>
    apiService.post(`comments/`, { ...params })
  );
  return { mutate, isSuccess, data };
}

export function useCommentDelete() {
  // TODO remove id fields
  const { mutate, isSuccess, data } = useMutation('useCommentDelete', (params) =>
    apiService.delete(`comments/`, { ...params })
  );
  return { mutate, isSuccess, data };
}
export function useCommentPut() {
  // TODO remove id fields
  const { mutate, isSuccess, data } = useMutation('useCommentPut', (params) =>
    apiService.put(`comments/`, { ...params })
  );
  return { mutate, isSuccess, data };
}
