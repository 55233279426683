import React from 'react';
import { Toolbar, IconButton, Divider } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PropTypes from 'prop-types';
import { DrawerStyled } from './sidebar.styled';
import CollapsedRouteLink from './colapsed-route/colapsed-route.component';
import { dashboardRoutes } from '../../constants/routes';

const Sidebar = ({ open, toggleDrawer }) => (
  <DrawerStyled variant="permanent" open={open}>
    <Toolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1],
      }}
    >
      <IconButton onClick={toggleDrawer}>
        <ChevronLeftIcon />
      </IconButton>
    </Toolbar>
    <Divider />
    {dashboardRoutes
      .filter(({ visible }) => visible)
      .map(({ icon, title, path, children }) => (
        <CollapsedRouteLink path={path} icon={icon} title={title} subroutes={children} />
      ))}
  </DrawerStyled>
);

Sidebar.propTypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

export default Sidebar;
