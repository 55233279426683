import { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import {
  NotificationContext,
  NotificationStatus,
} from '../components/notification-bar/notification-bar.context';
import { useSeoGet, useSeoPut } from '../services/seoService';
import { useLanguage } from './useLanguage';

export function useEditSeo(page) {
  // TODO remove language from requests
  const { language } = useLanguage();
  const { updateNotification } = useContext(NotificationContext);
  const { data, isLoading, mutate } = useSeoGet();
  const { mutate: onUpdate } = useSeoPut();

  useEffect(() => {
    if (page) mutate({ page });
  }, [page, language]);

  const formik = useFormik({
    initialValues: data ? data[0] : {},
    onSubmit: (values) => {
      if (values.length > 0) {
        values.map((item) => onUpdate(item));
      } else {
        onUpdate({ ...values });
      }
      updateNotification({ message: 'Successful!', status: NotificationStatus.success });
    },
    enableReinitialize: true,
  });

  return { data, formik, isLoading };
}
