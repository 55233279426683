import React, { useEffect } from 'react';
import { Paper } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import CardsList from '../../components/cards-list/cards-list.component';
import { useBannerList } from '../../services/bannersService';
import { useLanguage } from '../../utils/useLanguage';

function Banners() {
  const { language } = useLanguage();
  const { mutate, data } = useBannerList();

  const onUpdate = () => {
    mutate();
  };

  useEffect(() => {
    onUpdate();
  }, []);

  useEffect(() => {
    onUpdate();
  }, [language]);

  return (
    <Paper sx={{ p: 2, gap: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <CardsList
        items={data || []}
        addMoreText="Добавить баннер"
        addMoreLink={{
          pathname: `/dashboard/banners/${uuidv4()}`,
          search: `?lang=${language}`,
          state: { showLanguageTabs: false },
        }}
        onUpdate={onUpdate}
      />
    </Paper>
  );
}

export default Banners;
