import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { searchParameters } from '../constants/routes';

export function useLanguage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const language = useMemo(() => searchParams.get(searchParameters.language), [searchParams]);

  const setLanguage = (newValue) => {
    setSearchParams({ [searchParameters.language]: newValue });
  };

  return { language, setLanguage };
}
