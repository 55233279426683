import React, { useState } from 'react';
import {
  Toolbar,
  Box,
  IconButton,
  Avatar,
  Container,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import { Outlet, Link as RouterLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import Sidebar from '../../components/sidebar/sidebar.component';
import { AppBar } from './dashboard-layout.styled';
import { routes } from '../../constants/routes';
import LanguageTabs from '../../components/language-tabs/language-tabs.component';

const DashboardLayout = ({ languageTabs }) => {
  const [open, setOpen] = useState(true);
  const breadcrumbs = useBreadcrumbs(routes);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px',
          }}
          variant="secondary"
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Главная страница
          </Typography>
          <IconButton color="inherit">
            <Avatar>A</Avatar>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Sidebar open={open} toggleDrawer={toggleDrawer} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs sx={{ mb: 2 }} separator="›" aria-label="breadcrumb">
                {breadcrumbs.splice(2).map(({ breadcrumb, match }) => (
                  <Link
                    component={RouterLink}
                    to={match}
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/"
                  >
                    {breadcrumb}
                  </Link>
                ))}
              </Breadcrumbs>
              {languageTabs && <LanguageTabs />}
              <Outlet />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

DashboardLayout.propTypes = {
  languageTabs: PropTypes.bool,
};

export default DashboardLayout;
