import React from 'react';
import { bannerTypes } from '../../constants/blockTypes';
import ImageUpload from '../../components/image-upload/image-upload.components';

const Main = () => (
  <div>
    <ImageUpload type={bannerTypes.best_seller_banner} />
  </div>
);

export default Main;
