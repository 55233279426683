import React from 'react';
import { Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Form, FormikProvider } from 'formik';
import PaperFooter from '../../components/paper-footer/paper-footer.component';
import { blockTypes } from '../../constants/blockTypes';
import { useEditBlock } from '../../utils/useEditBlock';

function VideoBlock() {
  const { data, formik } = useEditBlock(blockTypes.video_block_banner);
  const { getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form key={JSON.stringify(data)} autoComplete="off" noValidate>
        <Paper sx={{ p: 2, gap: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <TextField label="Title" type="text" fullWidth required {...getFieldProps('title')} />
          <TextField
            label="Description"
            type="text"
            minRows={3}
            fullWidth
            required
            multiline
            {...getFieldProps('description')}
          />
          <TextField
            name="Video"
            label="Video"
            type="text"
            id="video"
            required
            fullWidth
            {...getFieldProps('link_video')}
          />
          <TextField
            required
            label="Button text"
            type="text"
            {...getFieldProps('link_button_text')}
          />
          <TextField required label="Button link" type="text" {...getFieldProps('link_button')} />
          <PaperFooter />
        </Paper>
      </Form>
    </FormikProvider>
  );
}

export default VideoBlock;
