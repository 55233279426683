import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useBannerDelete, useBannerList, useBannerPut } from '../../services/bannersService';
import EditBanner from './edit-banner.component';
import { useLanguage } from '../../utils/useLanguage';
import { changeBannerType } from '../../utils/banners';

function EditBannerContainer() {
  const params = useParams();
  const navigate = useNavigate();
  const [banner, setBanner] = useState();
  const { language } = useLanguage();
  const { mutate: getBannersList, data: bannersList, isSuccess } = useBannerList();
  const { mutate: deleteBanner } = useBannerDelete();
  const { mutate: saveBannerRequest, data: newBanner, isSuccess: bannerCreated } = useBannerPut();

  const currentDataItem = useMemo(() => {
    if (isSuccess && Array.isArray(bannersList) && bannersList.length > 0) {
      return bannersList.find(({ id }) => id === params.id);
    }
    return undefined;
  }, [isSuccess]);

  const saveBanner = (value) => {
    saveBannerRequest(value);
    navigate(-1);
  };

  const onCancel = () => {
    if (currentDataItem === undefined) {
      deleteBanner({ id: banner.id, language: banner.language });
    }
    navigate(-1);
  };

  useEffect(() => {
    if (isSuccess) {
      if (currentDataItem !== undefined) {
        setBanner(changeBannerType(currentDataItem));
      } else {
        saveBannerRequest({
          button_link1: '',
          button_text1: '',
          button_link2: '',
          button_text2: '',
          id: params.id,
          language,
          type: 'main_banner',
        });
      }
    }
  }, [currentDataItem, isSuccess]);

  useEffect(() => {
    if (language) getBannersList({ language });
  }, [language]);

  useEffect(() => {
    getBannersList();
  }, []);

  useEffect(() => {
    if (bannerCreated) {
      setBanner({
        ...{
          title: '',
          description: '',
          button_link1: '',
          button_text1: '',
          button_link2: '',
          button_text2: '',
          id: params.id,
          language,
          type: 'main_banner',
        },
        ...((newBanner || [])[0] || []),
      });
    }
  }, [bannerCreated]);

  return banner && isSuccess ? (
    <EditBanner item={banner} saveBanner={saveBanner} onCancel={onCancel} />
  ) : (
    <div />
  );
}

export default EditBannerContainer;
