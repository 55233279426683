import { useMutation } from 'react-query';
import { apiService } from './api-service';

export function useAuthService() {
  const { mutate, isSuccess, data } = useMutation('useAuthService', () =>
    apiService.post('authorization/authorization/', {
      token_site: 'fdsfdsfdsgfd%^7dsajhbe76rfvhgR%^RFD#27vbduyveg65ER%FGD56rf436rfgdskf',
      name: 'test',
      pass: 'test', // пока что открытый, потом зашифруем
      pin: '000', // число 3 знака Обязательное поле
      phone: '+380687358904',
    })
  );
  return { mutate, isSuccess, data };
}
