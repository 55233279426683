import { useContext } from 'react';
import { useMutation } from 'react-query';
import { apiService } from './api-service';
import {
  NotificationContext,
  NotificationStatus,
} from '../components/notification-bar/notification-bar.context';
import { useLanguage } from '../utils/useLanguage';

export function useSeoGet() {
  const { language } = useLanguage();
  // TODO remove id fields
  const { mutate, isSuccess, data } = useMutation('useSeoGet', (params) =>
    apiService.post(`CEOForPages/`, { language, id: '', ...params })
  );
  return { mutate, isSuccess, data };
}

export function useSeoPut() {
  const { language } = useLanguage();
  const { updateNotification } = useContext(NotificationContext);
  const { mutate, isSuccess, data } = useMutation(
    'useSeoPut',
    (item) => apiService.put(`CEOForPages/`, { language, ...item }),
    {
      onSuccess: () => {
        updateNotification({ message: 'Successful!', status: NotificationStatus.success });
      },
    }
  );
  return { mutate, isSuccess, data };
}
