import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import { theme } from './themes';
import {
  NotificationStatus,
  NotificationContext,
} from './components/notification-bar/notification-bar.context';
import NotificationBar from './components/notification-bar/notification-bar';
import { AuthProvider } from './utils/authContext';

const App = () => {
  const [notification, setNotification] = useState({
    message: '',
    status: NotificationStatus.success,
  });
  const contextValue = { notification, updateNotification: setNotification };
  return (
    <Router>
      <AuthProvider>
        <ThemeProvider theme={theme({})}>
          <NotificationContext.Provider value={contextValue}>
            <Routes />
            <NotificationBar />
          </NotificationContext.Provider>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
