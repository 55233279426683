import React from 'react';
import { Paper, TextField } from '@mui/material';
import { Form, FormikProvider } from 'formik';
import CardsList from '../../components/cards-list/cards-list.component';
import PaperFooter from '../../components/paper-footer/paper-footer.component';
import { useEditBlock } from '../../utils/useEditBlock';
import { blockTypes } from '../../constants/blockTypes';

function RelationProducts() {
  const { data, formik, onUpdate } = useEditBlock(blockTypes.selection_banner);
  const { getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form key={JSON.stringify(data)} autoComplete="off" noValidate>
        <Paper sx={{ p: 2, gap: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <TextField
            fullWidth
            required
            name="title"
            label="Title"
            type="text"
            id="Title"
            {...getFieldProps('title')}
          />
          <TextField
            required
            name="Button text"
            label="Button text"
            type="text"
            id="Button text"
            {...getFieldProps('link_button_text')}
          />
          <TextField
            required
            name="Button link"
            label="Button link"
            type="text"
            id="Button text"
            {...getFieldProps('link_button')}
          />
          <CardsList items={data.banners} onUpdate={onUpdate} />
          <PaperFooter />
        </Paper>
      </Form>
    </FormikProvider>
  );
}

export default RelationProducts;
