/* eslint-disable */
import React from 'react';
import { Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Form, FormikProvider } from 'formik';
import CardsList from '../../components/cards-list/cards-list.component';
import PaperFooter from '../../components/paper-footer/paper-footer.component';
import { blockTypes } from '../../constants/blockTypes';
import { useEditBlock } from '../../utils/useEditBlock';

function Bestsellers() {
  const { data, formik, onUpdate } = useEditBlock(blockTypes.best_seller_banner);
  const { getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form key={JSON.stringify(data)} autoComplete="off" noValidate>
        <Paper sx={{ p: 2, gap: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <TextField
            fullWidth
            required
            key="title"
            name="title"
            label="Title"
            type="text"
            id="title"
            {...getFieldProps('title')}
          />
          <CardsList
            items={data.banners}
            addMoreLink="/dashboard/edit-banner"
            onUpdate={onUpdate}
          />
          <PaperFooter />
        </Paper>
      </Form>
    </FormikProvider>
  );
}

export default Bestsellers;
