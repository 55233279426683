import React from 'react';
import { Paper, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Form, FormikProvider } from 'formik';
import CardsList from '../../components/cards-list/cards-list.component';
import PaperFooter from '../../components/paper-footer/paper-footer.component';
import { blockTypes } from '../../constants/blockTypes';
import { useEditBlock } from '../../utils/useEditBlock';

function Collections() {
  const { data, formik, onUpdate } = useEditBlock(blockTypes.collection_banner, true);
  const { values, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form key={JSON.stringify(data)} autoComplete="off" noValidate>
        <Paper sx={{ p: 2, gap: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {values &&
            Array.isArray(values) &&
            values.length > 0 &&
            values.map((item, index) => (
              <Stack
                direction="column"
                spacing={2}
                sx={{ width: '100%', justifyContent: 'flex-end', mb: 2 }}
              >
                <TextField
                  label="Description"
                  type="text"
                  minRows={3}
                  fullWidth
                  required
                  multiline
                  {...getFieldProps(`[${index}].description`)}
                />
                <TextField
                  label="Button text"
                  type="text"
                  required
                  {...getFieldProps(`[${index}].link_button_text`)}
                />
                <TextField
                  label="Button link"
                  type="text"
                  required
                  {...getFieldProps(`[${index}].link_button`)}
                />
              </Stack>
            ))}
          <CardsList items={data.banners} onUpdate={onUpdate} />
          <PaperFooter />
        </Paper>
      </Form>
    </FormikProvider>
  );
}

export default Collections;
