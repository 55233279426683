import React, { useContext, useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { NotificationContext } from './notification-bar.context';

function NotificationBar() {
  const [open, setOpen] = useState(false);
  const { notification } = useContext(NotificationContext);
  useEffect(() => {
    if (notification.message) setOpen(true);
  }, [notification]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={notification.status}>
        {notification.message}
      </Alert>
    </Snackbar>
  );
}

export default NotificationBar;
