import React, { useMemo } from 'react';
import { ListItem, ListItemIcon, ListItemText, Box, ListItemButton } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';
import { RouterLink } from '../sidebar.styled';

const RouteLink = ({ path, icon, title, onClick, collapsed }) => {
  const itemProps = useMemo(
    () => (onClick ? { button: true, onClick } : { component: RouterLink, to: path || '' }),
    [onClick, path, collapsed]
  );
  return (
    <ListItem disablePadding>
      <ListItemButton {...itemProps} selected={collapsed}>
        <ListItemIcon>
          <ListItemIcon>{icon || <Box component="span" />}</ListItemIcon>
        </ListItemIcon>
        <ListItemText primary={title} />
        {onClick && (
          <Box
            component={collapsed ? ArrowDropDownIcon : ArrowDropUpIcon}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
};

RouteLink.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  path: PropTypes.string,
  onClick: PropTypes.func,
  collapsed: PropTypes.bool,
};

export default RouteLink;
