import React from 'react';
import { Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Form, FormikProvider } from 'formik';
import PaperFooter from '../../components/paper-footer/paper-footer.component';
import { useEditSeo } from '../../utils/useEditSeo';
import TagsInput from '../../components/tag-input/tag-input.component';

function Seo() {
  const { data, formik } = useEditSeo('main');

  const { getFieldProps, values, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form key={JSON.stringify(data)} autoComplete="off" noValidate>
        <Paper sx={{ p: 2, gap: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <TextField fullWidth required label="Title" type="text" {...getFieldProps('title')} />
          <TextField
            required
            fullWidth
            label="Description"
            type="text"
            {...getFieldProps('description')}
          />
          <TagsInput
            onChange={(tags) => setFieldValue('keywords', tags)}
            tags={
              values.keywords && typeof values.keywords === 'string'
                ? values.keywords.split(',')
                : values.keywords || []
            }
            fullWidth
            variant="outlined"
            id="keywords"
            name="Keywords"
            label="Keywords"
          />
          <PaperFooter />
        </Paper>
      </Form>
    </FormikProvider>
  );
}

export default Seo;
