export const blockTypes = {
  best_seller_banner: 'best_seller_banner',
  collection_banner: 'collection_banner',
  selection_banner: 'selection_banner',
  video_block_banner: 'video_block_banner',
  seazon_hit_banner: 'seazon_hit_banner',
  main_banner: 'main_banner',
  about_us: 'about_us',
};

const baseBanner = { id: '', type: '', language: '' };

export const bannerTypes = {
  main_banner: {
    label: 'Главный баннер',
    value: 'main_banner',
    fields: {
      ...baseBanner,
      image: '',
      title: '',
      description: '',
      button_link1: '',
      button_text1: '',
      button_link2: '',
      button_text2: '',
    },
  },
  best_seller_banner: {
    label: 'Best seller',
    value: 'best_seller_banner',
    fields: { ...baseBanner, image: '', title: '', description: '', link: '' },
  },
  collection_banner: {
    label: 'Коллекции',
    value: 'collection_banner',
    fields: { ...baseBanner, image: '', title: '', description: '', link: '' },
  },
  selection_banner: {
    label: 'Избранные',
    value: 'selection_banner',
    fields: { ...baseBanner, image: '', title: '', description: '', link: '' },
  },
};
