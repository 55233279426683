import React, { useState } from 'react';
import { List, Collapse } from '@mui/material';
import PropTypes from 'prop-types';
import RouteLink from '../route/route.component';

const CollapsedRouteLink = ({ icon, title, path, subroutes }) => {
  const [collapsed, setCollapsed] = useState();

  return !subroutes ? (
    <RouteLink path={`/dashboard/${path}`} icon={icon} title={title} />
  ) : (
    <>
      <RouteLink
        icon={icon}
        title={title}
        collapsed={collapsed}
        onClick={() => setCollapsed(!collapsed)}
      />
      <Collapse in={collapsed} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subroutes &&
            subroutes
              .filter(({ visible }) => visible)
              .map((item) => (
                <RouteLink
                  path={`/dashboard/${path}/${item.path}`}
                  icon={item.icon}
                  title={item.title}
                />
              ))}
        </List>
      </Collapse>
    </>
  );
};

CollapsedRouteLink.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  path: PropTypes.string,
  subroutes: PropTypes.array,
};

export default CollapsedRouteLink;
